import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import './assets/styles/_index.scss';
import { isEmpty } from "lodash";

const OffPlanPayment = (props) => {
    const { t } = useTranslation()
    const { locale } = useLocalization()
    return (
        <>
            {!isEmpty(props?.paymentplanData) &&
                <section className="off-plan-payment-wrapper" id="payment_plan">
                    <Container>
                        <Row>
                            <Col>
                                <div class="property-desc-title">{t("paymentplan")}</div>
                            </Col>
                        </Row>
                        <div className="off-plan-payment-grid">
                            {props?.paymentplanData && props?.paymentplanData.length > 0 && props?.paymentplanData.map((data) => {
                                return (
                                    <div className="off-plan-payment-card">
                                        <div className="off-plan-payment-title">{data.title}</div>
                                        <div className="off-plan-payment-text">{data.content}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </Container>
                </section>
            }
        </>
    )
}

export default OffPlanPayment